<template>
  <b-container class="mt-4">
    <b-spinner v-if="loading"></b-spinner>
    <b-row v-if="!loading" class="mb-4">
      <b-col class="d-flex justify-content-start align-items-center">
        <h4>Eligibility settings for onboarding clients</h4>
      </b-col>
    </b-row>
    <b-row v-if="!loading">
      <b-col>
        <table class="table">
          <thead>
          <tr>
            <th>Client ID</th>
            <th>Name</th>
            <th>Availity Enabled</th>
            <th>Eligibility File Enabled</th>
            <th>Eligibility File Folder</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="client in clients" :key="client.id">
            <td>{{ client.id }}</td>
            <td>{{ client.display_name }}</td>
            <td>
            <span v-if="client.eligibility.availity" class="text-success">
            <feather type="check"></feather>
            </span>
              <span v-else class="text-danger">
            <feather type="x"></feather>
            </span>
            </td>
            <td>
            <span v-if="client.eligibility.eligibility_file" class="text-success">
            <feather type="check"></feather>
            </span>
              <span v-else class="text-danger">
            <feather type="x"></feather>
            </span>
            </td>
            <td>
              {{ client.eligibility.path }}
            </td>
            <td>
              <b-button variant="light"
                        v-if="$acl.check('onboarding.eligibility.configs.edit')"
                        :to="`/onboarding/clients/${client.id}/eligibility`">
                <!-- TODO Check why named route is not working -->
                <feather type="edit-2"></feather>
              </b-button>
            </td>
          </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'EligibilityConfigs',
  data() {
    return {
      clients: [],
      loading: true,
    };
  },
  async beforeMount() {
    try {
      this.loading = true;
      const clients = await this.$store.dispatch('Core/fetchClients');
      const { data } = await axios.get('v3/clients/eligibility/configs', {
        params: {
          clientIds: clients.map(c => c.id),
        },
      });
      this.clients = clients.map(c => this.loadEligibilityInfo(c, data));
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    loadEligibilityInfo(client, eligibilityData) {
      const eligibilityForClient = eligibilityData.find(eligibility => eligibility.client_id === client.id) || {};

      client.eligibility = {
        eligibility_file: eligibilityForClient.eligibility_file || '',
        availity: eligibilityForClient.availity || false,
        path: eligibilityForClient.folder || '',
        subscriber_mapping: {
          first_name: eligibilityForClient.column_number_first_name || 0,
          last_name: eligibilityForClient.column_number_last_name || 0,
          dob: eligibilityForClient.column_number_dob || 0,
          email: eligibilityForClient.column_number_email || 0,
          gender: eligibilityForClient.column_number_gender || 0,
          phone: eligibilityForClient.column_number_phone || 0,
          address: eligibilityForClient.column_number_address || 0,
          address2: eligibilityForClient.column_number_address2 || 0,
          city: eligibilityForClient.column_number_city || 0,
          state: eligibilityForClient.column_number_state || 0,
          country: eligibilityForClient.column_number_country || 0,
          zip_code: eligibilityForClient.column_number_zip_code || 0,
          service_type: eligibilityForClient.column_number_service_type || 0,
        },
        dependent_mapping: {
          first_name: eligibilityForClient.column_number_dependent_first_name || 0,
          last_name: eligibilityForClient.column_number_dependent_last_name || 0,
          dob: eligibilityForClient.column_number_dependent_dob || 0,
          email: eligibilityForClient.column_number_dependent_email || 0,
          relationship: eligibilityForClient.column_number_dependent_relationship || 0,
          gender: eligibilityForClient.column_number_dependent_gender || 0,
          phone: eligibilityForClient.column_number_dependent_phone || 0,
          address: eligibilityForClient.column_number_dependent_address || 0,
          address2: eligibilityForClient.column_number_dependent_address2 || 0,
          city: eligibilityForClient.column_number_dependent_city || 0,
          state: eligibilityForClient.column_number_dependent_state || 0,
          country: eligibilityForClient.column_number_dependent_country || 0,
          zip_code: eligibilityForClient.column_number_dependent_zip_code || 0,
        },
      };

      return client;
    },
  },
};
</script>

<style scoped>

</style>
